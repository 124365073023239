<!--
 * @Author: dongjia
 * @Date: 2021-08-23 09:46:16
 * @LastEditTime: 2021-08-30 11:57:50
 * @LastEditors: aleaner
 * @Description: 微信公众号配置
 * @FilePath: \saas-admin-vue\src\modules\wechat-app\views\wx-common-config.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="wechat-config" v-loading="loading">
    <reminder style="margin-bottom: 20px;">
      <el-row>
        <el-col :span="23">
          <span>·</span>
          <div>如需使用H5支付请填写微信公众号配置以及前往
            <a class="reminder-link"
               href="https://pay.weixin.qq.com/index.php/core/home/login?return_url=%2Findex.php%2Fcore%2Fhome%2Fdefault_header" target="_blank">微信商户平台</a>
            -> 登录商户号 -> 产品中心 -> 开通H5支付以及
            <a class="reminder-link"
              href="https://www.yuque.com/docs/share/7abf6078-0042-4ca4-8640-b4288b1053a5" target="_blank">查看H5支付开通指引</a>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="23">
          <span>·</span>
          <div>JS接口安全域名文件上传后，需返回微信公众号后台 -> 设置与开发 -> 公众号设置 -> 功能设置 -> JS接口安全域名 / 网页授权域名，在域名上填入 h5.shetuan.pro 后保存</div>
        </el-col>
      </el-row>
    </reminder>
    <el-form size="medium" :model="config" :rules="rule" label-width="120px" label-position="right"
      class="small-form" ref="config">
      <div class="modular">
        <p class="modular-title">微信公众号配置</p>
        <div class="modular-content" style="margin-left: 11px;">
          <el-form-item label="微信公众号名称：" prop="name">
            <el-input v-model="config.name" placeholder="请输入微信公众号" />
          </el-form-item>
          <el-form-item label="AppID：" prop="app_id">
            <el-input v-model="config.app_id" placeholder="请输入AppID" />
            <div class="info">
              <i class="el-icon-info" />
              <span style="margin-left: 6px">微信公众号后台 -> 设置与开发 -> 开发 -> 基本配置 -> 公众号开发信息</span>
            </div>
          </el-form-item>
          <el-form-item label="AppSecret：" prop="is_set_app_secret">
            <template v-if="config.is_set_app_secret">
              <span style="margin-right: 10px;">已填写</span><el-button type="text" @click="showInputDialog('修改信息')">修改</el-button>
            </template>
            <el-button v-else type="text" @click="showInputDialog('填写信息')">填写</el-button>
            <div class="info">
              <i class="el-icon-info" />
              <span style="margin-left: 6px">微信公众号后台 -> 设置与开发 -> 开发 -> 基本配置 -> 公众号开发信息</span>
            </div>
          </el-form-item>
          <el-form-item label="JS接口安全域名：" prop="mp_verify">
            <UploadFileButton :text-name.sync="config.mp_verify" :uploaded-text-name="config.mp_verify"
              button-text="请上传.txt文件" />
            <div class="info">
              <i class="el-icon-info" />
              <span style="margin-left: 6px">微信公众号后台 -> 设置与开发 -> 公众号设置 -> 功能设置 -> JS接口安全域名 -> 下载文件</span>
            </div>
          </el-form-item>
          <el-form-item label="网页授权域名：" prop="">
            <p>完成【JS接口安全域名文件】上传后，需要在微信公众号后台【网页授权域名】填入以下域名：h5.shetuan.pro</p>
            <div class="info">
              <i class="el-icon-info" />
              <span style="margin-left: 6px">微信公众号后台 -> 设置与开发 -> 公众号设置 -> 功能设置 -> 网页授权域名 -> 域名上填入 h5.shetuan.pro 后保存即可</span>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <el-dialog v-if="inputDialog.show"
               class="dialog-vertical" width="450px"
               :visible="inputDialog.show" :title="inputDialog.title"
               @close="close">
      <el-form ref="inputDialog" class="small-form" size="medium"
               :model="inputDialog.data" :rules="rule"
               label-width="95px"
               @submit.native.prevent>
        <el-form-item label="AppSecret：" prop="app_secret">
          <el-input v-model="inputDialog.data.app_secret" v-focus placeholder="请输入AppSecret" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="close">取消</el-button>
        <el-button size="small" type="primary" @click="confirm">确定</el-button>
      </template>
    </el-dialog>
    <fixed-action-bar>
      <el-button type="primary" @click="saveConfig('config')">保存</el-button>
      <!-- <el-button>取消</el-button> -->
    </fixed-action-bar>
  </div>
</template>

<script>
import { wxConfig, saveConfig } from "../api/wx-common-config";
import FixedActionBar from "../../../base/layout/FixedActionBar";
import UploadFileButton from "../components/UploadFileButton.vue";
import Reminder from '../../common/components/Reminder'
export default {
  components: {Reminder, FixedActionBar, UploadFileButton },
  data() {
    var checkIsSet = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入AppSecret'))
      }
      callback()
    }
    return {
      loading: false,
      config: {
        mp_verify: "",
        app_secret: "",
        is_set_app_secret: 0
      },
      rule: {
        name: [
          { required: true, message: "请输入公众号名称", trigger: "blur" },
        ],
        app_id: [{ required: true, message: "请输入AppID", trigger: "blur" }],
        app_secret: [
          { required: true, message: "请输入AppSecret", trigger: "blur" },
        ],
        is_set_app_secret: [
          { required: true, message: "请输入AppSecret", trigger: "blur" },
          { validator: checkIsSet, trigger: "blur" },
        ],
        mp_verify: [
          {
            required: true,
            message: "请上传网页授权域名文件",
            trigger: "change",
          },
        ],
      },
      status: false, //上传状态
      uploadIndex: -1,
      percentage: 0, //上传进度条
      proError: false, //上传出错
      inputDialog: {
        show: false,
        loading: false,
        title: '',
        data: {
          app_secret: ""
        },
      }
    };
  },
  created() {
    this.loading = true;
    wxConfig()
      .then((res) => {
        this.config = res.data;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      });
  },
  methods: {
    showInputDialog(title) {
      this.inputDialog.title = title
      this.inputDialog.show = true
    },
    confirm() {
      this.config.app_secret = this.inputDialog.data.app_secret
      this.$refs.inputDialog.validate(valid => {
        if (valid) {
          this.config.is_set_app_secret = 1
          this.close()
        }
      })
    },
    close() {
      this.inputDialog.show = false
      this.inputDialog.data.app_secret = ''
    },
    saveConfig(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          this.loading = true;
          saveConfig({
            config: this.config,
          })
            .then((res) => {
              this.$message.success(res.msg);
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wechat-config {
  .title-label {
    margin-bottom: 20px;
  }

  .el-icon-upload2 {
    margin-right: 5px;
  }

  .medium-form {
    max-width: 600px;
  }
}
</style>
