/*
 * @Author: dongjia
 * @Date: 2021-08-23 10:04:10
 * @LastEditTime: 2021-08-23 10:06:48
 * @LastEditors: aleaner
 * @Description: 微信公众号配置接口
 * @FilePath: \saas-admin-vue\src\modules\wechat-app\api\wx-common-config.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';
import upload from "@/base/utils/upload4";

// 上传授权证书
export const uploadFile = data => {
  return upload({
    url: '/admin/admin/media/UploadVerifyFile',
    data,
    typeArr: ["file"]
  });
}
// 获取小程序版本设置信息
export const wxConfig = data => {
  return api({
    url: '/admin/admin/settings/wechatPublicConfig',
    method: 'post',
    data
  });
};

// 保存配置
export const saveConfig = data => {
  return api({
    url: '/admin/admin/settings/setWechatPublicConfig',
    method: 'post',
    data
  });
};